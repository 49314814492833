<template>
  <v-container
    fluid
    tag="section"
    data-cy="pageGestionComptesClients"
  >
    <base-material-card
      icon="mdi-account-settings-outline"
      inline
      class="px-5 py-3"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          {{ $t('compteClient.compte_client') }}
        </div>
      </template>

      <v-divider class="mt-3" />

      <v-data-table
        :headers="headers"
        :items="listeComptesClients"
        :loading="loading"
        :footer-props="{
          itemsPerPageOptions: [10,50,100,500],
          itemsPerPageText: $t('general.lignes_par_page'),
          pageText: `{0}-{1} ${$t('general.de')} {2}`
        }"
        data-cy="dtComptesClients"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            class="px-2 ml-1 primary"
            min-width="0"
            small
            @click="onEditer(item)"
          >
            <v-icon
              small
            >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn
            class="px-2 ml-1 warning"
            min-width="0"
            small
            @click="onSupprimer(item)"
          >
            <v-icon
              small
            >
              mdi-trash-can-outline
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-btn
        class="primary"
        data-cy="btnAjouterComptesClient"
        @click="onAjouter()"
      >
        {{ $t('compteClient.creer_un_compte_client') }}
      </v-btn>
    </base-material-card>
  </v-container>
</template>

<script>
  import restApiService from '@/services/restApiService.js'
  import i18n from '@/i18n.js'

  export default {
    data: () => ({
      loading: true,
      options: {},
      nUsagers: 0,
      listeComptesClients: [],
      compteClient: {
        nom: '',
      },
      headers: [
        { text: i18n.t('general.nom'), value: 'nom' },
        { text: i18n.t('general.actions'), value: 'actions', align: 'center' },
      ],
    }),
    watch: {
      options: {
        handler () {
          this.getData()
        },
        deep: true,
      },
    },
    mounted () {
      this.getData()
    },
    methods: {
      getData () {
        restApiService
          .get('/api/compte-clients')
          .then((result) => {
            this.listeComptesClients = result.data
            this.loading = false
          })
          .catch((erreur) => alert(erreur))
      },
      onEditer (item) {
        this.$router.push({ path: '/admin/editionCompteClient/' + item.id })
      },
      async onAjouter () {
        const reponse = await this.$dialog.prompt({
          title: i18n.t('compteClient.creer_un_compte_client'),
          text: i18n.t('compteClient.veuillez_saisir_le_nom_du_compte_client'),
          rules: [
            v => !!v || i18n.t('general.le_champ_est_requis'),
          ],
          textField: {
            type: 'text',
            id: 'nouveauCompteClientNomlInput',
          },
          actions: {
            false: i18n.t('general.annuler'),
            true: {
              color: 'red',
              text: i18n.t('general.confirmer'),
              handle: () => {
                return new Promise(resolve => {
                  this.compteClient.nom = document.getElementById('nouveauCompteClientNomlInput').value
                  if (this.compteClient.nom) {
                    resolve(true)
                  } else { resolve(false) }
                })
              },
            },
          },
        }).catch(() => {
          console.log('Prompt dismissed')
        })

        if (reponse) {
          restApiService
            .post('/api/compte-clients', this.compteClient)
            .then((result) => {
              this.$router.push({ path: `/admin/editionCompteClient/${result.data.id}` + '?nouveau=true' })
            })
            .catch((erreur) => alert(erreur))
        }
      },
      async onSupprimer (compteClient) {
        const reponse = await this.$dialog.confirm({
          title: i18n.t('compteClient.supprimer_un_compte_client'),
          text: i18n.t('compteClient.voulez_vous_vraiment_supprimer_ce_compte_client'),
        })
        if (reponse) {
          restApiService.delete('/api/compte-clients/' + compteClient.id).then((result) => {
            this.getData()
          }).catch((erreur) => alert(erreur))
        }
      },
    },
  }
</script>
